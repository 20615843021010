.anniversary-container {
  height: 100vh;
  background: url("../img/anniversary/konfetti_bg.png") no-repeat center;
  background-size: cover;
  display: grid;
  justify-items: center;
  align-items: center;
}
.anniversary-container__image {
  width: 70%;
  height: auto;
}
.anniversary-container__link {
  align-self: start;
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
  color: #E22279;
  border: 2px solid #e22279;
  padding: 0.5rem;
  background-color: white;
}
@media (min-width: 1200px) {
  .anniversary-container__image {
    width: 50%;
    height: auto;
  }
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #fff;
}
h1 {
  font-family: 'Love Ya Like A Sister' !important;
  font-size: 2em !important;
  font-weight: 700 !important;
  color: #000 !important;
}
h2 {
  font-family: 'Lato', sans-serif !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}
h4 {
  font-family: 'Lora';
  font-size: 1.5rem;
}
h5 {
  font-family: 'Love Ya Like A Sister';
  font-weight: 700;
}
time {
  font-family: 'Lato', sans-serif;
}
p {
  font-size: 1rem;
}
a.btn.btn-primary {
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  color: #fff;
  background-color: #fa6900;
  border-color: #fa6900;
}
.navbar-nav .nav-item a.nav-link {
  font-family: Lato, sans-serif;
  font-size: .938em;
  font-weight: 700;
  color: #0099ed;
}
.navbar-nav .nav-item a.nav-link:hover {
  border-bottom: 1px solid #c90f0d;
  color: #8ad2fb;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #8ad2fb;
  cursor: auto;
}
strong {
  font-weight: bold;
}
.card-img-top {
  width: 50% !important;
}
.beth {
  width: 42% !important;
}
.course-img {
  width: 100% !important;
}
.description-bold {
  font-weight: 700;
}
.about-links-container a {
  display: block;
  text-decoration: underline;
  color: #000;
  font-family: Lato, sans-serif;
  font-weight: 700;
}
.about-links-container a .fa-chevron-circle-right {
  color: #fa6900;
}
button {
  border: none;
  background-color: transparent;
}
figure {
  margin: 0;
}
#logo .slogan {
  font-family: 'Love Ya Like A Sister', sans-serif;
  font-weight: 600;
  color: #000;
}
#logo .slogan p {
  font-size: 2.2rem;
}
nav li.active {
  border-bottom: 1px solid #c90f0d;
}
nav .navbar-toggler {
  border: 0px;
}
.credit {
  font-size: 0.8rem;
}
#motiv .credit {
  position: absolute;
  color: #fff;
  right: 2rem;
  bottom: .1rem;
}
#motiv-foerderer {
  position: relative;
}
#motiv-history .container {
  position: relative;
  max-width: 100%;
  background-color: rgba(220, 160, 140, 0);
}
#motiv-history .container .col {
  margin: auto;
}
#motiv-history .container .col p {
  font-family: 'LORA-REGULAR';
  font-style: italic;
  color: #fff;
}
#motiv-history .container .col p q {
  quotes: ' \201C ' ' \201D ';
}
#motiv-history .container .col p {
  font-size: 2.3rem;
  margin-top: 39rem;
  text-shadow: 2px 2px #000;
  color: #fff;
}
#start-article {
  font-family: 'LORA-REGULAR';
  color: #4c4646;
  margin-top: 2rem;
}
#start-article .music-kid img {
  max-width: 100%;
}
#start-article p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin-bottom: .5em;
}
#start-article p span {
  padding-left: .2em;
}
#history-article .container {
  display: grid;
  justify-items: center;
}
#history-article p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#history-article .card-body {
  padding: 0;
}
#history-article .card-body img {
  padding-bottom: .5em;
}
#history-article .card-body figcaption {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#history-article .reward {
  width: 55%;
}
#history-article .reward figure figcaption {
  max-width: fit-content;
}
#links {
  background-color: #edf8fe;
}
#links svg {
  color: #fa6900;
  position: relative;
  -moz-animation-duration: 9s;
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
#links svg.fa-arrow-right {
  -moz-animation-name: arrowMoveRight;
  -webkit-animation-name: arrowMoveRight;
  animation-name: arrowMoveRight;
}
#links svg.fa-arrow-left {
  -moz-animation-name: arrowMoveLeft;
  -webkit-animation-name: arrowMoveLeft;
  animation-name: arrowMoveLeft;
}
#links a {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #3bade6;
  padding: 0 0.5rem;
}
.separate-line {
  width: 8em;
  height: .1em;
  background-color: #c90f0d;
  margin-bottom: .7em;
}
#course-list .container {
  background-color: rgba(220, 160, 140, 0);
}
#course-list .container p {
  padding-top: 2rem;
  text-align: center;
  font-family: 'Love Ya Like A Sister';
  font-size: 2em;
  font-weight: 700;
  color: #000;
}
#course-list .container ul {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  list-style: none;
}
#course-list .container ul li {
  margin-bottom: 1em;
  padding-left: .5rem;
}
#course-list .container ul li .fa-music {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
}
#course-list .container ul li a {
  color: #fa6900;
  padding-left: .2rem;
}
#course-list .container ul li:nth-child(even) {
  padding-left: 1rem;
}
#course-list .container ul .drummers {
  white-space: nowrap;
}
#lecturers {
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 700;
}
#lecturers .lecturer-container {
  padding: 0;
  display: grid;
  gap: .5rem;
}
#lecturers .lecturer-container .lecturer-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  justify-content: start;
  border-radius: 10px;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__quote {
  padding: 0 1rem;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__quote blockquote {
  font-size: 1.3rem;
  font-style: italic;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image {
  position: relative;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .img-fluid {
  width: 100%;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .first {
  border-radius: 10px 0 0 10px;
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .last {
  border-radius: 0 10px 10px 0;
}
@media (max-width: 575px) {
  #lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .first,
  #lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .last {
    border-radius: 10px 10px 0 0;
  }
}
#lecturers .lecturer-container .lecturer-wrapper .lecturer-wrapper__image .credit {
  position: absolute;
  bottom: 0;
}
#lecturers .lecturer-container .lecturer-wrapper--space-between {
  justify-content: space-between;
}
#lecturers .lecturer-container .lecturer-wrapper--first-order-color {
  background-color: #47a7d7;
}
#lecturers .lecturer-container .lecturer-wrapper--reversed-order-color {
  background-color: #ff9800;
}
#accordion .btn {
  font-size: 2rem;
  color: #000;
  font-family: 'Love Ya Like A Sister', sans-serif;
}
#accordion figcaption {
  width: 90%;
  font-size: 1rem;
  font-family: Lato, sans-serif;
  font-weight: 300;
}
#pinwall {
  background-color: #f2f6f8;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#pinwall .container.lazyloaded {
  width: 42rem;
  height: auto;
  background: url('../img/pinwall/music_note_frame.png') center center no-repeat;
  background-size: cover;
}
#pinwall .container.lazyloaded .img-wrapper {
  margin-left: 4rem;
  width: 35rem;
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  -moz-column-gap: 0.5em;
  -webkit-column-gap: 0.5em;
  column-gap: 0.5em;
}
#pinwall .container.lazyloaded .img-wrapper .image {
  position: relative;
}
#pinwall .container.lazyloaded .img-wrapper .image:last-child {
  top: 1rem;
  left: -1rem;
}
#pinwall .container.lazyloaded .img-wrapper .image .credit {
  position: absolute;
  bottom: .1rem;
  font-size: .6rem;
  color: #e7e7e6;
  font-weight: 700;
  backdrop-filter: blur(57px);
}
#pinwall .container.lazyloaded .img-wrapper .image .credit.credit--right {
  right: 1rem;
}
#pinwall .container.lazyloaded .img-wrapper .image .credit-cem {
  left: .4rem;
}
#pinwall .container.lazyloaded .img-wrapper .image .credit-dally {
  left: .4rem;
}
#pinwall .container.lazyloaded .img-wrapper .image .credit-walbaum {
  left: .4rem;
}
#pinwall .container.lazyloaded .img-wrapper .image .credit-fayssal {
  left: .4rem;
}
#pinwall .container.lazyloaded .img-wrapper .image:nth-child(even) {
  transform: rotate(12deg);
}
#pinwall .container.lazyloaded .img-wrapper .image:nth-child(odd) {
  transform: rotate(-12deg);
}
#pinwall .container.lazyloaded .img-wrapper .image img {
  max-width: 100%;
  height: auto;
}
#motiv-registration {
  position: relative;
  text-align: center;
}
#registration-articles article {
  border-top: 1px solid #fa6900;
}
#registration-articles article .number p {
  font-size: 3rem;
}
#registration-articles article .number h2 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 2rem !important;
  font-weight: 500;
}
#registration-articles article .registration-description p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#registration-articles ul {
  font-family: 'Lato';
}
#registration-articles ul li::before {
  content: '\2022 ';
  color: #85919d;
  font-weight: bold;
  /* If you want it to be bold */
  width: 1em;
  /* Also needed for space (tweak if needed)*/
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}
#news-articles .card {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#news-articles .card .card-img-top {
  height: 100%;
}
#news-articles .card .card-body {
  width: 100%;
}
#news-articles .card .card-body a {
  font-family: 'Lato', sans-serif;
  color: #bababa;
  padding: .3rem;
  font-size: .8rem;
  font-weight: 600;
}
#news-articles .card .card-body a:hover {
  background-color: #fa6900;
  color: #fff;
  text-decoration: none;
}
.arrow {
  cursor: pointer;
  transform: rotate(90deg);
  position: fixed;
  right: 0;
  bottom: 0;
  display: block;
}
#event-article .row {
  margin-top: 7.5rem;
  margin-bottom: 10.5rem;
}
#event-article .row p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#event-article .row .card .card-body p {
  font-size: 1rem !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 300 !important;
}
#directors {
  margin-top: 2rem;
}
#directors .card {
  position: relative;
  text-align: center;
  border-width: 0;
}
#directors .card img {
  border-style: solid;
  border-width: 3px;
  border-color: rgba(132, 134, 136, 0.2);
  margin-left: 0;
}
#directors .card p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#directors .card .credit {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 2rem;
  bottom: 0;
  margin-top: 19.5rem;
  font-size: 0.8rem;
}
#motiv-about {
  position: relative;
  text-align: center;
}
#motiv-about .credit {
  position: absolute;
  color: #fff;
  right: 2rem;
  bottom: .5rem;
}
#about-section p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#about-section p .about-section__quote {
  font-weight: 700 ;
  color: #808080;
}
#about-section h2 {
  font-family: 'Love Ya Like A Sister' !important;
  font-size: 2em !important;
  font-weight: 700 !important;
  color: #000 !important;
}
#about-section .name {
  white-space: nowrap;
}
#bsh .col-md-4 {
  position: relative;
  text-align: center;
}
#bsh .col-md-4 .credit {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*margin-top: 13.5rem;*/
  font-size: .8rem;
}
#bsh p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
#bsh .bsh-logo {
  margin-top: -1rem;
}
#courses-cards .row .card {
  text-align: center;
}
#courses-cards .row .card .card-head {
  position: relative;
}
#motiv-media {
  position: relative;
  text-align: center;
}
#protection {
  font-family: 'Lato', sans-serif;
}
#logos .container {
  display: flex;
  flex-flow: column;
  gap: 2rem;
}
#logos .container .row {
  align-items: end;
  justify-content: center;
}
#logos .container .row .j-m-sponsor {
  text-align: center;
}
#logos .container .second-line {
  justify-content: center;
}
#logos .container .cooperation-container .cooperation-headline {
  text-align: center;
  margin-bottom: 2rem;
}
#logos .container .cooperation-container .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
#logos .container .cooperation-container .logo-container .esche {
  width: 25%;
}
#logos .container .cooperation-container .logo-container .delux {
  width: 25%;
}
#logos .container .cooperation-container .logo-container img {
  width: 100%;
  height: auto;
}
footer {
  background-color: #3bade6;
  font-family: 'Lato', sans-serif;
  color: #fff;
}
footer .contact {
  background-color: #3bade6;
}
footer .contact .container {
  padding-top: 2rem;
}
footer .foot-note {
  background-color: #fff;
  color: #000;
}
footer .foot-note h2,
footer .foot-note p {
  font-size: 0.9rem;
}
footer .foot-note h2 a,
footer .foot-note p a {
  color: #000;
  text-decoration: none;
}
.years {
  font-family: 'Lato', sans-serif;
}
.years .years__wrapper {
  display: grid;
  justify-content: center;
  margin-bottom: 4em;
}
.years .years__wrapper .image {
  width: 80%;
  margin: auto;
}
.years .years__wrapper .image img {
  width: 100%;
}
.years .years__wrapper .video {
  position: relative;
  height: 25vh;
  padding-top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.years .years__wrapper .video iframe {
  position: absolute;
  top: 0;
  max-width: 100%;
  max-height: 100%;
}
.years .years__wrapper .no-video {
  text-align: center;
}
.years .years__wrapper .no-video p {
  margin: 0;
}
.years .years__wrapper .divider {
  width: 3em;
  margin: auto;
}
.years .years__wrapper .divider img {
  width: 100%;
}
.greeting .container {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
}
.greeting .container p {
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.card-head .credit {
  position: absolute;
  color: #fff;
  right: 2rem;
  bottom: .1rem;
}
.quotes {
  position: absolute;
  left: 0;
  right: 0;
  font-size: clamp(0.9rem, 1.9vw, 3rem);
  font-family: LORA-REGULAR;
  bottom: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.donation {
  background-color: #f2f6f8;
  margin-top: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.donation .donation__img {
  max-width: 100%;
}
.scroll-top {
  display: grid;
  justify-content: center;
}
.scroll-top .scroll-top__button {
  color: #fa6900;
  transform: rotate(-90deg);
  margin-bottom: -0.5em;
  cursor: pointer;
}
.scroll-top .scroll-top__button svg {
  font-size: clamp(2rem, 3.9vw, 2.5rem);
  cursor: pointer;
}
.ndr-report {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  justify-content: center;
}
.ndr-report img {
  width: 100%;
}
@-moz-keyframes arrowMoveRight {
  0% {
    left: 0;
  }
  3% {
    left: 0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: 0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes arrowMoveRight {
  0% {
    left: 0;
  }
  3% {
    left: 0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: 0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes arrowMoveRight {
  0% {
    left: 0;
  }
  3% {
    left: 0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: 0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes arrowMoveLeft {
  0% {
    left: 0;
  }
  3% {
    left: -0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: -0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes arrowMoveLeft {
  0% {
    left: 0;
  }
  3% {
    left: -0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: -0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes arrowMoveLeft {
  0% {
    left: 0;
  }
  3% {
    left: -0.5rem;
  }
  6% {
    left: 0;
  }
  9% {
    left: -0.5rem;
  }
  12% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@media (max-width: 575px) {
  #logo .musica-logo {
    text-align: center;
  }
  #logo .slogan p {
    text-align: center;
    font-size: 1.4rem;
  }
  #logo .herz {
    text-align: center;
  }
  nav.navbar {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  nav .container {
    min-width: 100%;
  }
  nav .container .row {
    width: 100%;
  }
  nav .container .row .navbar-collapse {
    margin-left: -24px;
    margin-right: -53px;
  }
  nav .container .row .navbar-collapse ul {
    background-color: #ebeff2;
    padding-left: 2rem;
  }
  nav .container .row .navbar-collapse li {
    padding-left: .5rem;
    border-bottom: 1px solid #e0e0e8;
  }
  nav .container .row .navbar-collapse li .nav-link {
    color: #777777;
  }
  #motiv {
    height: 14.75rem;
    background: url('../img/motiv/gino_banner_xs.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-about {
    height: 14.75rem;
    background: url('../img/motiv_about/about_motiv_xs.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-about .credit {
    font-size: .8rem;
  }
  #motiv-registration {
    height: 12.75rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_xs.jpg') top right no-repeat;
    background-size: cover;
  }
  #motiv-registration .credit {
    font-size: .8rem;
  }
  #motiv-media {
    height: 9.75rem;
    background: url('../img/media/media_motiv_xs.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-history {
    height: 14.75rem;
    background: url('../img/history/history_motiv_xs.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-history .container .row .col .quotes {
    font-size: .9rem;
    margin-top: 13rem;
  }
  #courses-motiv {
    height: 14.75rem;
    background: url('../img/courses_motiv/courses_motiv_xs.jpg') top center no-repeat;
    background-size: cover;
  }
  #courses-cards .container {
    width: 20rem;
  }
  #courses-cards .container .row:nth-child(3) div:last-child .card .credit {
    margin-bottom: 1.5rem;
  }
  #courses-cards .container .row:last-child .card .credit {
    margin-bottom: 3rem;
  }
  .donation .container {
    width: 20rem;
  }
  #motiv-foerderer {
    height: 14.75rem;
    background: url('../img/foerderer/motiv_foerderer_xs.jpg') top center no-repeat;
    background-size: cover;
    display: none;
  }
  #motiv-foerderer .container .row .col .quotes {
    font-size: .9rem;
    margin-top: 5rem;
  }
  #start-article .row:last-child p {
    margin-bottom: 6rem;
  }
  #bsh img {
    width: 11rem;
  }
  #bsh .bsh-logo {
    margin-top: 0;
  }
  #bsh .col-md-4 .credit {
    font-size: .6rem;
    margin-top: 6.2rem;
  }
  #course-list.lazyloaded {
    height: 100%;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #course-list.lazyloaded p {
    font-size: 1.3em;
  }
  #course-list.lazyloaded ul {
    font-size: .8em;
  }
  #course-list.lazyloaded .row:last-child {
    margin-left: 2em;
    margin-top: -3em;
  }
  #pinwall {
    display: none;
  }
  #directors img {
    width: 11rem;
    margin-left: 1rem;
  }
  #directors .card .credit {
    margin-top: 29rem;
  }
  #news-articles {
    margin-top: 2rem;
  }
  #news-articles .card {
    margin-bottom: .5rem;
  }
  #news-articles .card .card-img-top {
    width: 10rem;
    height: 100%;
  }
  #news-articles .card .card-body h4 {
    font-size: 1.2rem;
  }
  #news-articles .card .card-body time {
    font-size: .8rem;
  }
  #event-article .row {
    margin-top: 0;
    margin-bottom: 0;
  }
  .refugees-image {
    width: 50%;
  }
  footer .foot-note .col-md-4 {
    padding-bottom: 1rem;
  }
  footer .container {
    text-align: center;
  }
}
@media (min-width: 576px) and (max-width: 799px) {
  #logo .slogan p {
    font-size: 1.6rem;
  }
  nav.navbar {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  nav .container {
    min-width: 100%;
  }
  nav .container .row {
    width: 100%;
  }
  nav .container .row .navbar-collapse {
    margin-left: -24px;
    margin-right: -53px;
  }
  nav .container .row .navbar-collapse ul {
    background-color: #ebeff2;
    padding-left: 2rem;
  }
  nav .container .row .navbar-collapse li {
    padding-left: .5rem;
    border-bottom: 1px solid #e0e0e8;
  }
  nav .container .row .navbar-collapse li .nav-link {
    color: #777777;
  }
  #motiv {
    height: 15.4rem;
    background: url('../img/motiv/gino_banner_sm.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-about {
    height: 15.4rem;
    background: url('../img/motiv_about/about_motiv_sm.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-registration {
    height: 15.4rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_sm.jpg') top right no-repeat;
    background-size: cover;
  }
  #motiv-history {
    height: 15.4rem;
    background: url('../img/history/history_motiv_sm.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-history .container .row .col .quotes {
    font-size: 1.3rem;
    margin-top: 12.9rem;
  }
  #motiv-directors {
    height: 15.4rem;
    background: url('../img/directors/vorstand_motiv_sm.jpg') top center no-repeat;
    background-size: cover;
  }
  #courses-motiv {
    height: 15.4rem;
    background: url('../img/courses_motiv/courses_motiv_sm.jpg') top center no-repeat;
    background-size: cover;
  }
  #courses-cards .container {
    width: 20rem;
  }
  #courses-cards .container .row:first-child div:nth-child(2) .card .trommel-credit {
    bottom: 11rem;
  }
  #courses-cards .container .row:nth-child(3) div:first-child .card .credit {
    margin-bottom: 0;
  }
  #courses-cards .container .row:nth-child(3) div:last-child .card .credit {
    margin-bottom: 1.5rem;
  }
  #courses-cards .container .row:last-child div:first-child .card .credit {
    margin-bottom: 7rem;
  }
  .donation .container {
    width: 20rem;
  }
  #motiv-foerderer {
    height: 15.4rem;
    background: url('../img/motiv/gino_banner_sm.jpg') top center no-repeat;
    background-size: cover;
    display: none;
  }
  #bsh img {
    width: 11rem;
  }
  #bsh .col-md-4 .credit {
    font-size: .6rem;
    margin-top: 6.2rem;
  }
  #motiv-media {
    height: 13rem;
    background: url('../img/media/media_motiv_sm.jpg') center no-repeat;
    background-size: cover;
  }
  #start-article .row:last-child p {
    margin-bottom: 6rem;
  }
  #course-list.lazyloaded {
    height: 100%;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #course-list.lazyloaded .row:last-child {
    margin-left: 2em;
    margin-top: -3em;
  }
  #pinwall {
    display: none;
  }
  #directors img {
    width: 11rem;
  }
  #directors .card .credit {
    margin-top: 17rem;
  }
  #lecturers .col-md-4 {
    text-align: center;
  }
  #lecturers .ingrid-quotes,
  #lecturers .olle-qoutes,
  #lecturers .willie-quotes {
    padding-left: 15px;
  }
  #lecturers blockquote {
    font-size: 1.5rem;
  }
  #news-articles {
    margin-top: 2rem;
  }
  #news-articles .card {
    margin-bottom: .5rem;
  }
  #news-articles .card .card-img-top {
    width: 10rem;
    height: 100%;
  }
  #event-article .row {
    margin-top: 0;
    margin-bottom: 0;
  }
  footer .foot-note .col-md-4 {
    padding-bottom: 1rem;
  }
  footer .contact .container {
    text-align: center;
  }
  footer .contact .container .row {
    justify-content: center;
  }
  footer .contact .container .row .contact__adress {
    text-align: center;
  }
  #registration-articles ul {
    font-family: 'Lato';
  }
  #registration-articles ul li::before {
    content: '\2022 ';
    color: #85919d;
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1.2em;
    /* Also needed for space (tweak if needed)*/
    margin-left: -1.2em;
    /* Also needed for space (tweak if needed) */
  }
}
@media (min-width: 768px) and (max-width: 799px) {
  #logo .slogan p {
    font-size: 1.9rem;
  }
  #bsh .col-md-4 .credit {
    font-size: .6rem;
    margin-top: 8.5rem;
  }
  #directors .card .credit {
    margin-top: 6.5rem;
    font-size: .5rem;
  }
  #motiv-history {
    height: 23rem;
    background: url('../img/history/history_motiv_md.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-history .container .row .col .quotes {
    font-size: 1.5rem;
    margin-top: 19.9rem;
  }
  #motiv-about {
    height: 23rem;
    background: url('../img/motiv_about/about_motiv_md.jpg') center no-repeat;
    background-size: cover;
  }
  #about-section img {
    width: 23rem;
  }
  #course-list {
    height: 28.7rem;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #course-list ul:first-child li:nth-child(3),
  #course-list ul:first-child li:last-child {
    white-space: nowrap;
  }
  #courses-cards .row:nth-child(3) div:last-child .card .credit {
    margin-bottom: 3rem;
  }
  #courses-cards .row:last-child div:first-child .card .credit {
    margin-bottom: 4.5rem;
  }
  #motiv-foerderer {
    height: 17rem;
    background: url('../img/foerderer/motiv_foerderer_md.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer .container .col p {
    font-size: 1.2em;
    margin-top: 13rem;
  }
  #motiv-media {
    height: 17rem;
    background: url('../img/media/media_motiv_md.jpg') center no-repeat;
    background-size: cover;
  }
  #accordion .card:last-child .card-body {
    /*  width: 48rem;*/
  }
  #motiv-registration {
    height: 23rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_md.jpg') top right no-repeat;
    background-size: cover;
  }
  #news-articles {
    margin-top: 2rem;
  }
  #news-articles .card {
    margin-bottom: .5rem;
  }
  #news-articles .card .card-img-top {
    width: 10rem;
    height: 100%;
  }
  #lecturers > div:first-child .col-md-4 .credit {
    margin-top: 13rem;
  }
  #lecturers > div:nth-child(5) .col-md-4 .credit {
    margin-top: 20rem;
  }
  #lecturers > div:nth-child(8) .col-md-4 .credit {
    text-shadow: 0.5px 0.5px #000;
    margin-top: 13rem;
  }
  #lecturers > div:last-child .col-md-4 .credit {
    margin-top: 12rem;
  }
}
@media (min-width: 768px) and (max-width: 799px) and (orientation: portrait) {
  #lecturers > div:nth-child(13) .col-md-4 .credit {
    margin-top: 12rem;
  }
  #news-articles .card .card-img-top {
    width: 8rem;
  }
}
@media (min-width: 800px) and (max-width: 992px) {
  #bsh .col-md-4 .credit {
    margin-top: 8.5rem;
    font-size: .6rem;
  }
  #directors .card .credit {
    margin-top: 6.5rem;
    font-size: .5rem;
  }
  #logo .slogan p {
    font-size: 1.6rem;
  }
  #motiv {
    height: 23rem;
    background: url('../img/motiv/gino_banner_md.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-about {
    height: 23rem;
    background: url('../img/motiv_about/about_motiv_md.jpg') top center no-repeat;
    background-size: cover;
  }
  #about-section img {
    width: 23rem;
  }
  #motiv-registration {
    height: 23rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_md.jpg') top right no-repeat;
    background-size: cover;
  }
  #motiv-media {
    height: 19rem;
    background: url('../img/media/media_motiv_md.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-history {
    height: 23rem;
    background: url('../img/history/history_motiv_md.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-history .container .row .col .quotes {
    font-size: 2rem;
    margin-top: 19rem;
  }
  #motiv-foerderer {
    height: 22rem;
    background: url('../img/foerderer/motiv_foerderer_md.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer .container .col p {
    font-size: 1.2em;
    margin-top: 17rem;
  }
  #courses-motiv {
    height: 23rem;
    background: url('../img/courses_motiv/courses_motiv_md.jpg') top center no-repeat;
    background-size: cover;
  }
  #start-article .row .col:last-child {
    padding-left: 0em;
  }
  #start-article .row .col:last-child img {
    width: 300px;
  }
  #quotes-carousel .row {
    margin-left: 2em;
    margin-right: 0;
  }
  #course-list {
    height: 28.7rem;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #course-list ul:first-child li:nth-child(3),
  #course-list ul:first-child li:last-child {
    white-space: nowrap;
  }
  #news-articles {
    margin-top: 2rem;
  }
  #news-articles .card {
    margin-bottom: .5rem;
  }
  #news-articles .card .card-img-top {
    width: 10rem;
    height: 100%;
  }
  #courses-cards .container .row:nth-child(3) div:last-child .card .credit {
    margin-bottom: 3rem;
  }
  #courses-cards .container .row:last-child div:first-child .card .credit {
    margin-bottom: 4.5rem;
  }
  #courses-cards .container .row:last-child div:last-child .card .credit {
    margin-bottom: 1.5rem;
  }
  #lecturers > div:first-child .col-md-4 .credit {
    margin-top: 12rem;
  }
  #lecturers > div:nth-child(5) .col-md-4 .credit {
    margin-top: 20rem;
  }
  #lecturers > div:nth-child(8) .col-md-4 .credit {
    text-shadow: 0.5px 0.5px #000;
    margin-top: 13rem;
  }
  #lecturers > div:last-child .col-md-4 .credit {
    margin-top: 12rem;
  }
}
@media (min-width: 992px) and (max-width: 1299px) {
  #bsh .col-md-4 .credit {
    margin-top: 11.5rem;
  }
  #directors .card .credit {
    margin-top: 9rem;
    font-size: .7rem;
  }
  #logo .slogan p {
    font-size: 1.6rem;
  }
  #motiv-media {
    height: 25rem;
    background: url('../img/media/media_motiv_lg.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv {
    height: 19rem;
    background: url('../img/motiv/gino_banner_lg.jpg') center center no-repeat;
    background-size: cover;
  }
  #motiv-history {
    height: 31rem;
    background: url('../img/history/history_motiv_xl.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-history .container .row .col .quotes {
    margin-top: 25.9rem;
  }
  #motiv-foerderer {
    height: 23rem;
    background: url('../img/foerderer/motiv_foerderer_xl.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer .container .col p {
    font-size: 2rem;
    margin-top: 17rem;
    text-shadow: 2px 2px black;
    line-height: 2.5rem;
  }
  #motiv-about {
    height: 31rem;
    background: url('../img/motiv_about/about_motiv_xlg.jpg') center center no-repeat;
    background-size: cover;
  }
  #motiv-registration {
    height: 27rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_xl.jpg') top right no-repeat;
    background-size: cover;
  }
  #course-list {
    height: 28.7rem;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #lecturers > div:nth-child(5) .col-md-4 .credit {
    /*right: 6.7rem;*/
  }
  #lecturers > div:nth-child(8) .col-md-4 .credit {
    bottom: 1rem;
    /*right: 5rem;*/
    color: #fff;
    text-shadow: 0.5px 0.5px #000;
  }
  #courses-cards .container .row:first-child div:nth-child(2) .card .trommel-credit {
    bottom: 11rem;
  }
  #courses-cards .container .row:nth-child(3) div:first-child .card .credit {
    margin-bottom: 0rem;
  }
  #courses-cards .container .row:nth-child(3) div:nth-child(3) .card .credit {
    margin-bottom: 1.5rem;
  }
  #courses-cards .container .row:last-child div:first-child .card .credit {
    margin-bottom: 3rem;
  }
  #registration-articles ul {
    font-family: 'Lato';
  }
  #registration-articles ul li::before {
    content: '\2022 ';
    color: #85919d;
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1.2em;
    /* Also needed for space (tweak if needed)*/
    margin-left: -1.2em;
    /* Also needed for space (tweak if needed) */
  }
}
@media (min-width: 1048px) and (max-width: 1344px) {
  #motiv-foerderer {
    height: 29rem;
    background: url('../img/foerderer/motiv_foerderer_xl.jpg') center no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1200px) {
  #bsh .col-md-4 .credit {
    margin-top: 14rem;
  }
  #directors .card .credit {
    margin-top: 11rem;
  }
  #logo .slogan p {
    font-size: 1.7rem;
  }
  #motiv {
    height: 26rem;
    background: url('../img/motiv/gino_banner_lg.jpg') center center no-repeat;
    background-size: cover;
  }
  #courses-motiv {
    height: 44rem;
    background: url('../img/courses_motiv/courses_motiv_xlg.jpg') center center no-repeat;
    background-size: cover;
  }
  #motiv-registration {
    height: 29rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_xl.jpg') top right no-repeat;
    background-size: cover;
  }
  #course-list {
    height: 28.7rem;
    background: url('../img/kurslist_bg.jpg') top center no-repeat;
    background-size: cover;
  }
  #logos {
    margin-top: 7rem;
  }
  #courses-cards .container .row:first-child div:nth-child(2) .card .trommel-credit {
    bottom: 9.5rem;
  }
  #courses-cards .container .row:first-child div:nth-child(3) .card .credit {
    margin-bottom: 0;
  }
  #courses-cards .container .row:nth-child(2) div:nth-child(2) .card .credit {
    margin-bottom: 0;
  }
  #courses-cards .container .row:last-child div:first-child .card .credit {
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1300px) {
  #motiv-media {
    height: 45rem;
    background: url('../img/media/media_motiv_xl.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv {
    height: 44rem;
    background: url('../img/motiv/gino_banner_xlg.jpg') center center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer {
    height: 29rem;
    background: url('../img/foerderer/motiv_foerderer_xl.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer .container .col p {
    font-size: 3rem;
    margin-top: 15rem;
    text-shadow: 4px 4px black;
    line-height: 3.5rem;
  }
  #motiv-history {
    height: 44rem;
    background: url('../img/history/history_motiv_xl.jpg') top center no-repeat;
    background-size: cover;
  }
  #motiv-about {
    height: 44rem;
    background: url('../img/motiv_about/about_motiv_xlg.jpg') center center no-repeat;
    background-size: cover;
  }
  #motiv-registration {
    height: 35rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_xl.jpg') top right no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1344px) and (max-width: 1668px) {
  #motiv-foerderer {
    height: 37rem;
    background: url('../img/foerderer/motiv_foerderer_xl.jpg') center no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1669px) {
  #courses-cards .container .row:nth-child(3) div:last-child .card .credit {
    margin-bottom: 1.5rem;
  }
  #motiv-foerderer {
    height: 42rem;
    background: url('../img/foerderer/motiv_foerderer_xl.jpg') center no-repeat;
    background-size: cover;
  }
  #motiv-foerderer .container .col p {
    font-size: 3rem;
    margin-top: 24rem;
    text-shadow: 4px 4px black;
    line-height: 3.5rem;
  }
  #motiv-registration {
    height: 42rem;
    background: url('../img/motiv_anmeldung/anmeldung_motiv_xl.jpg') top right no-repeat;
    background-size: cover;
  }
}
